<template>
  <div>
    <v-card max-width="800" max-height="800" class="mx-4" :elevation="24">
      <v-container class="card">
        <v-row justify="end" class="d-flex justify-end">
          <v-spacer />
          <v-col
            cols="12"
            sm="6"
            align="start"
            justify="end"
            class="d-flex align-end justify-start venusa-title"
          >
            <div>
              <h1
                class="mb-n3"
                :class="$vuetify.breakpoint.mdAndUp ? 'venusa-font' : ''"
              >
                VENUSA
              </h1>
              <h1 :class="$vuetify.breakpoint.mdAndUp ? 'venusa-font' : ''">
                LIVE
              </h1>
            </div>
          </v-col>
          <v-col cols="12" sm="6" class="pa-8 height-allowed">
            <v-scroll-x-transition mode="out-in">
              <reset-password-section></reset-password-section>
            </v-scroll-x-transition>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import ResetPasswordSection from "./ResetPasswordSection";
export default {
  name: "reset-password-card",
  components: {
    "reset-password-section": ResetPasswordSection
  },
  data() {
    return {};
  }
};
</script>

<style scoped>
.venusa-title {
  color: white !important;
  background: rgb(232, 84, 231);
  background: linear-gradient(
    90deg,
    rgba(232, 84, 231, 1) 8%,
    rgba(205, 140, 236, 1) 100%
  );
}

.venusa-font {
  font-size: 60px;
}

.card {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.position {
  position: absolute !important;
}
.position2 {
  position: relative !important;
}
.height-allowed {
  min-height: 442px !important;
}
</style>
