<template>
  <div>
    <br />
    <h2>Recuperación de contraseña</h2>
    <template v-if="!passwordChangedSuccessfully">
      <br />
      <v-row class="justify-center" no-gutters>
        <p class="letters-color">Ingresa tu nueva contraseña</p>
      </v-row>
      <br />
      <v-form ref="form">
        <v-text-field
          v-model="password"
          :rules="[rules.required, rules.minLength]"
          autofocus
          :type="showPassword ? 'text' : 'password'"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
          class="border"
          label="Contraseña"
          :readonly="loading"
          maxlength="50"
          counter
          outlined
        ></v-text-field>
        <v-text-field
          v-model="confirmationPassword"
          :rules="[rules.required, rules.matchingPasswords]"
          :type="showConfirmationPassword ? 'text' : 'password'"
          :append-icon="showConfirmationPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showConfirmationPassword = !showConfirmationPassword"
          class="border"
          label="Confirmación de contraseña"
          maxlength="50"
          counter
          :readonly="loading"
          outlined
        ></v-text-field>

        <v-btn
          @click="changePassword"
          class="mt-5 primary--text venusa-title-button"
          block
          medium
          color="primary"
          :loading="loading"
          :elevation="7"
          @keyup.enter="changePassword"
          >Cambiar Contraseña</v-btn
        >
      </v-form>
      <br />
    </template>
    <v-scroll-x-transition mode="out-in">
      <div v-if="passwordChangedSuccessfully">
        <v-icon color="primary" class="image-success" size="100"
          >mdi-checkbox-marked-circle-outline</v-icon
        >
        <br />
        <v-row class="justify-center" no-gutters>
          <h2 class="letters-color">¡Contraseña cambiada!</h2>
        </v-row>
        <br />
        <v-row class="justify-center" no-gutters>
          <p class="letters-color">Inicia sesión con tu nueva contraseña</p>
        </v-row>
        <br />
        <v-row class="justify-center" no-gutters>
          <span class="letters-color letters-cursor" @click="goToLoginSection()"
            >Ir a Inicio de sesión</span
          >
        </v-row>
      </div>
    </v-scroll-x-transition>
  </div>
</template>

<script>
import Repository from "@/services/repositories/repositoryFactory";
const UserRepository = Repository.get("users");

export default {
  name: "reset-password-section",
  data() {
    return {
      password: "",
      confirmationPassword: "",
      showPassword: false,
      showConfirmationPassword: false,
      passwordChangedSuccessfully: false,
      rules: {
        required: value => !!value || "Campo Obligatorio",
        minLength: value => value.length >= 8 || "Mínimo 8 caracteres",
        matchingPasswords: confirmationPassword =>
          confirmationPassword === this.password ||
          "Las contraseñas no coinciden"
      },
      loading: false
    };
  },
  methods: {
    async changePassword() {
      if (this.$refs.form.validate()) {
        const newPassword = {
          newPass: this.password,
          newPassConfirmation: this.confirmationPassword
        };
        this.loading = true;
        await UserRepository.updatePassword(newPassword)
          .then(() => {
            this.passwordChangedSuccessfully = true;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    goToLoginSection() {
      this.$router.push({ name: `login` });
    }
  },
  watch: {
    password() {
      if (this.confirmationPassword) {
        this.$refs.form.validate();
      }
    }
  }
};
</script>

<style scoped>
.venusa-title-button ::v-deep .v-btn__content {
  color: white !important;
}

.venusa-title-button ::v-deep .v-btn__loader {
  color: white !important;
}

.letters-color {
  color: var(--v-primary-base);
}

.letters-cursor {
  cursor: pointer;
}

.image-success {
  -webkit-filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.3));
  filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.3));
}
</style>
